// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-gallery-jsx": () => import("./../../../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-judging-panel-jsx": () => import("./../../../src/pages/judging-panel.jsx" /* webpackChunkName: "component---src-pages-judging-panel-jsx" */),
  "component---src-pages-nominations-jsx": () => import("./../../../src/pages/nominations.jsx" /* webpackChunkName: "component---src-pages-nominations-jsx" */),
  "component---src-pages-previous-winners-jsx": () => import("./../../../src/pages/previous-winners.jsx" /* webpackChunkName: "component---src-pages-previous-winners-jsx" */),
  "component---src-pages-privacy-notice-use-of-cookies-jsx": () => import("./../../../src/pages/privacy-notice-use-of-cookies.jsx" /* webpackChunkName: "component---src-pages-privacy-notice-use-of-cookies-jsx" */),
  "component---src-pages-sponsors-jsx": () => import("./../../../src/pages/sponsors.jsx" /* webpackChunkName: "component---src-pages-sponsors-jsx" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

